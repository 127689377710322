$(function () {
    app.burger.init();

    document.querySelectorAll('[data-section]').forEach((link) => {
        link.addEventListener('click', () => {
            window.scrollTo({
                top: document.querySelector(`#${link.getAttribute('data-section')}`).offsetTop,
                behavior: "smooth"
            });
        });
    });

    $('input[type="file"]').on('change', function () {
        const $input = $(this);
        const fileName = this.files[0].name;
        const $placeholder = $input.closest('label').find('.placeholder');
        $placeholder.addClass('attached');
        $placeholder.text(fileName);
    });

    const animatedItems = document.querySelectorAll('.animated');

    const callback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('in-view');
                observer.unobserve(entry.target)
            } else {
                entry.target.classList.remove('in-view');
            }
        });
    }

    const options = {
        // root: по умолчанию window, но можно задать любой элемент-контейнер
        rootMargin: '0px 0px 75px 0px',
        threshold: 0,
    };

    const observer = new IntersectionObserver(callback, options);

    animatedItems.forEach((item) => observer.observe(item));


    // input mask
    const phoneInput = document.querySelector('#phone');
    const maskOptions = {
        mask: '+{7}(000)000-00-00',
        lazy: false
    }

    let mask;

    phoneInput.addEventListener('focus', function () {
        mask = new IMask(phoneInput, maskOptions);
    });

    phoneInput.addEventListener('blur', function () {
        if (this.value.match('_')) {
            mask.masked.reset();
        }
    });
});